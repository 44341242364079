import React, { Fragment } from "react";
import Head from "next/head";
import { Typography, Container, Box } from "@mui/material";
import Header from "../components/common/Navbar";
import HeaderNew from "../components/common/NavbarNew";
// import BackstageHeader from "../components/navbars/backstage";
// import OnelinkHeader from '../components/navbars/onelink'
// import Footer from "../components/common/Footer";
import { useRouter } from "next/router";
// import MobileFooter from './common/MobileFooter'
import dynamic from "next/dynamic";
import NavbarNewHomePage from "./common/NavbarNewHomePage";

const Footer = dynamic(() => import("../components/common/Footer"));
const MobileFooter = dynamic(() => import("./common/MobileFooter"));

function Layout({ children }) {
  const router = useRouter();

  if (
    router.pathname == "/event/[...slug]" ||
    router.pathname == "/venue/[...slug]" ||
    router.pathname == "/deal/[...slug]" ||
    router.pathname == "/cart/[id]"
  ) {
    return (
      <Fragment>
        <Typography component="div">
          <Head>
            <title>Clubr</title>
            <link
              href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
            <link rel="icon" type="image/png" href="/favicon.png" />
          </Head>
          {/* <Header /> */}
          <NavbarNewHomePage />
          <Container>{children}</Container>
          <div className="footer desktopMode">
            <Footer />
          </div>
          {router.pathname == "/cart/[id]" ? (
            ""
          ) : (
            <div className="mobileMode">
              <MobileFooter />
            </div>
          )}
        </Typography>
      </Fragment>
    );
  } else if (
    router.pathname == "/types/[type]" ||
    router.pathname == "/T[slug]"
  ) {
    return (
      <Fragment>
        <Typography component="div">
          <Head>
            <title>Clubr</title>
            <link
              href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
            <link rel="icon" type="image/png" href="/favicon.png" />
          </Head>
          {children}
        </Typography>
      </Fragment>
    );
  } else if (
    router.pathname == "/backstage" ||
    router.pathname == "/backstage/thanks"
  ) {
    return (
      <Fragment>
        <Typography component="div">
          <Head>
            <title>Clubr</title>
            <link
              href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
            <link rel="icon" type="image/png" href="/favicon.png" />
          </Head>
          {/* <HeaderNew /> */}
          <NavbarNewHomePage />
          {children}
          <div className="footer desktopMode">
            <Footer />
          </div>
          <div className="mobileMode">
            <MobileFooter />
          </div>
        </Typography>
      </Fragment>
    );
  } else if (router.pathname == "/link") {
    return (
      <Fragment>
        <Typography component="div">
          <Head>
            <title>Clubr</title>
            <link
              href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
            <link rel="icon" type="image/png" href="/favicon.png" />
          </Head>
          {/* <HeaderNew /> */}
          <NavbarNewHomePage />
          {children}
          <div className="footer desktopMode">
            <Footer />
          </div>
          <div className="mobileMode">
            <MobileFooter />
          </div>
        </Typography>
      </Fragment>
    );
  } else if (router.pathname == "/") {
    return (
      <Fragment>
        <Typography component="div">
          <Head>
            <title>Clubr</title>
            <link
              href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
            <link rel="icon" type="image/png" href="/favicon.png" />
          </Head>
          {/* <HeaderNew /> */}
          <NavbarNewHomePage />
          {/* <Box sx={{width: '85%', mx: 'auto'}}>{children}</Box> */}
          <Box sx={{ mx: "auto" }}>{children}</Box>
          <div className="footer desktopMode">
            <Footer />
          </div>
          <div className="mobileMode">
            <MobileFooter />
          </div>
        </Typography>
      </Fragment>
    );
  } else if (router.pathname == "/selfOnboarding") {
    return (
      <Fragment>
        <Typography component="div">
          <Head>
            <title>Clubr</title>
            <link
              href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
            <link rel="icon" type="image/png" href="/favicon.png" />
          </Head>
          {/* <NavbarNewHomePage /> */}
          {/* <Box sx={{width: '85%', mx: 'auto'}}>{children}</Box> */}
          <Box sx={{ mx: "auto", px: 2 }}>{children}</Box>
          {/* <div className="footer desktopMode">
            <Footer />
          </div>
          <div className="mobileMode">
            <MobileFooter />
          </div> */}
        </Typography>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Typography component="div">
          <Head>
            <title>Clubr</title>
            <link
              href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
            <link rel="icon" type="image/png" href="/favicon.png" />
          </Head>
          {/* <Header /> */}
          <NavbarNewHomePage />
          <Container>{children}</Container>
          <div className="footer desktopMode">
            <Footer />
          </div>
          <div className="mobileMode">
            <MobileFooter />
          </div>
        </Typography>
      </Fragment>
    );
  }
}

export default Layout;
